import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import Link from "~src/components/Link";
import * as routes from "~src/lib/routes";
import SignInPage from "~src/pages/SignInPage";
import NotFoundPage from "~src/pages/NotFoundPage";

export default function App() {
  const [path, setPath] = useState(location.pathname + location.search);

  useEffect(() => {
    document.addEventListener("click", (event: Event) => {
      const target = event.target as HTMLAnchorElement;
      if (target.hasAttribute("data-link")) {
        event.preventDefault();
        const href = target.getAttribute("href");
        if (href) {
          history.pushState({}, "", href);
          setPath(href);
        }
      }
    });

    window.addEventListener("popstate", () => {
      setPath(location.pathname + location.search);
    });
  }, []);

  let Page = NotFoundPage;
  if (path === routes.signIn) {
    Page = SignInPage;
  }
  return <Page></Page>;
}
